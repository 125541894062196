import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import load from "../../../ImageLoder";
import PageHOC from "../../../PageHOC";
import Footer from "../../Footer";

class LifeOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sections: [
        {
          slug: "general",
          title: "General",
        },
      ],
      questions: [
        {
          isOpen: false,
          sectionSlug: "step-challenge",
          slug: "step",
          question: "How to synchronise step count records?",
          content: "sync-step-content",
          videoTitle: "sync-step-video-title",
          answer: "https://www.youtube.com/embed/Ri_CeovcMlg",
          icon: "./resources/images/elife/step-accordion-icon-step.png",
        },
        {
          isOpen: false,
          sectionSlug: "step-challenge",
          slug: "friend",
          question: "How to invite friends?",
          content: "invite-friend-step-content",
          videoTitle: "invite-friend-step-video-title",
          answer: "https://www.youtube.com/embed/i6_XK7BH6Rk",
          icon: "./resources/images/elife/step-accordion-icon-friend.png",
        },
        {
          isOpen: false,
          sectionSlug: "step-challenge",
          slug: "coins",
          question: "How to Earn Coins?",
          content: "earn-coins-step-content",
          videoTitle: "earn-coins-step-video-title",
          answer: "https://www.youtube.com/embed/h6xuCynPcmU",
          icon: "./resources/images/elife/step-accordion-icon-coin.png",
        },
        {
          isOpen: false,
          sectionSlug: "step-challenge",
          slug: "cert",
          question: "Electronic certificate",
          content: "cert-step-content",
          videoTitle: "cert-step-video-title",
          answer: "https://www.youtube.com/embed/6uP13SADYkk",
          icon: "./resources/images/elife/step-accordion-icon-cert.png",
        },
      ],
    };
  }
  toggleQuestion(sectionSlug, slug) {
    const newQuestions = [...this.state.questions];
    const questionIndex = newQuestions.findIndex(
      (question) =>
        question.slug === slug && question.sectionSlug === sectionSlug
    );
    if (questionIndex === -1) return;
    newQuestions[questionIndex].isOpen = !newQuestions[questionIndex].isOpen;
    this.setState({
      ...this.state,
      questions: newQuestions,
    });
  }

  static defaultProps = {
    wcag22aURL: "https://www.w3.org/WAI/WCAG2AA-Conformance",
    appStoreDownloadURL: "https://apps.apple.com/us/app/e-life/id6502993463",
    googelPlayDownloadURL:
      "https://play.google.com/store/apps/details?id=hk.gov.ehealth.life",
    huaweiDownloadURL: "https://appgallery.huawei.com/app/C110183237",
  };
  detectIOS() {
    return (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i)
    );
  }

  detectAndroid() {
    return navigator.userAgent.match(/Android/i);
  }
  detectHuawei() {
    return navigator.userAgent.match(/huawei/i);
  }
  render() {
    let isIOS = this.detectIOS();
    let isAndroid = this.detectAndroid();
    let isHuawei = this.detectHuawei();
    let {
      languageCodeMap,
      wcag22aURL,
      appStoreDownloadURL,
      googelPlayDownloadURL,
      huaweiDownloadURL,
    } = this.props;
    const { locale, t } = this.props;

    if (locale === "en") {
      appStoreDownloadURL = isIOS
        ? "https://apps.apple.com/app/e-life/id6502993463"
        : "https://apps.apple.com/us/app/e-life/id6502993463";
      googelPlayDownloadURL = isAndroid
        ? "https://play.google.com/store/apps/details?id=hk.gov.ehealth.life"
        : "https://play.google.com/store/apps/details?id=hk.gov.ehealth.life&hl=en-US";
      huaweiDownloadURL = isHuawei
        ? "https://appgallery.huawei.com/app/C110183237"
        : "https://appgallery.huawei.com/app/C110183237";
    } else if (locale === "zh-CN") {
      appStoreDownloadURL = isIOS
        ? "https://apps.apple.com/app/e-life/id6502993463"
        : "https://apps.apple.com/cn/app/e-life/id6502993463";
      googelPlayDownloadURL = isAndroid
        ? "https://play.google.com/store/apps/details?id=hk.gov.ehealth.life"
        : "https://play.google.com/store/apps/details?id=hk.gov.ehealth.life&hl=zh-CN";
      huaweiDownloadURL = isHuawei
        ? "https://appgallery.huawei.com/app/C110183237"
        : "https://appgallery.huawei.com/app/C110183237";
    } else if (locale === "zh-HK") {
      appStoreDownloadURL = isIOS
        ? "https://apps.apple.com/app/e-life/id6502993463"
        : "https://apps.apple.com/hk/app/e-life/id6502993463";
      googelPlayDownloadURL = isAndroid
        ? "https://play.google.com/store/apps/details?id=hk.gov.ehealth.life"
        : "https://play.google.com/store/apps/details?id=hk.gov.ehealth.life&hl=zh-HK";
      huaweiDownloadURL = isHuawei
        ? "https://appgallery.huawei.com/app/C110183237"
        : "https://appgallery.huawei.com/app/C110183237";
    }

    const answers = {
      "faq-section1-q02-answer": (
        <>
          {t("faq-section1-q02-answer-p1")}
          <br />
          {t("faq-section1-q02-answer-p2")}
          <a href="#" target="_blank">
            {t("faq-section1-q02-answer-p3")}
          </a>
        </>
      ),
      "faq-section4-q03-answer": (
        <>
          {t("faq-section4-q03-answer-p1")}
          <ul className="faq-list">
            <li>{t("faq-section4-q03-answer-p2")}</li>
          </ul>
        </>
      ),
    };

    return (
      <div
        className={` page-container page-container-${locale} static-page-with-banner`}
        id="MainContent"
      >
        <div className="overview-hero-banner">
          <div className="container-full">
            <div className="row vertical-align">
              <img
                className="desktop-show-hide-tab"
                src={load(
                  `./resources/images/elife/${locale}/step-challenge-banner-desktop.jpg`
                )}
              />
              <img
                className="tablet-show"
                src={load(
                  `./resources/images/elife/${locale}/step-challenge-banner-desktop.jpg`
                )}
              />
            </div>
          </div>
        </div>

        <div className="container ">
          <div className="row">
            <div className="col-lg-12 col-sm-12 overview-intro0">
              <img
                className=""
                src={load(`./resources/images/elife/step-challenge-intro.png`)}
              />
              {t("Step Challenge intro Content")}
            </div>
          </div>
        </div>

        <div
          id="overview-step-challenge-container"
          className="container-fluid "
        >
          <div className="container">
            <h2 className="life-section-title lion-deco">
              {t("Step Challenge event details title")}
            </h2>

            <img
              className="step-10000-main-img"
              src={load(`./resources/images/elife/10000steps-main-img.png`)}
            />

            <div className="container life-overview-3col">
              <div className="overview-item col-lg-4 col-sm-12 ">
                <div class="overview-item-content">
                  <img
                    className=""
                    src={load(
                      `./resources/images/elife/step-challenge-enrol-period.png`
                    )}
                  />
                  <div className="overview-item-text">
                    <h4>{t("Enrolment period")}</h4>
                    {t("Enrolment period content")}
                  </div>
                </div>
              </div>

              <div className="overview-item col-lg-4 col-sm-12 ">
                <div class="overview-item-content">
                  <img
                    className=""
                    src={load(
                      `./resources/images/elife/step-challenge-challenge-period.png`
                    )}
                  />
                  <div className="overview-item-text">
                    <h4>{t("Challenge period")}</h4>
                    {t("Challenge period content")}
                  </div>
                </div>
              </div>

              <div className="overview-item col-lg-4 col-sm-12 ">
                <div class="overview-item-content">
                  <img
                    className=""
                    src={load(`./resources/images/life-overview-intro3.png`)}
                  />
                  <div className="overview-item-text">
                    <h4>{t("Target participants")}</h4>
                    {t("Target participants content")}
                  </div>
                </div>
              </div>
            </div>
            <div className="row enrolment-row">
              <div className="enrolment-title">{t("enrolment title")}</div>
              <div className="col-md-6">
                <div className="individual-enrol-box elife-box-content">
                  <div className="game-mode-title">
                    <img
                      className="step-challenge-enrol-icon"
                      src={load(
                        `./resources/images/elife/individual-enrolment-img.png`
                      )}
                    />
                    {t("Individual enrolment")}
                  </div>
                  <div className="">{t("Individual enrolment content")}</div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="workplace-enrol-box elife-box-content">
                  <div className="game-mode-title">
                    <img
                      className="step-challenge-enrol-icon"
                      src={load(
                        `./resources/images/elife/workplace-enrolment-img.png`
                      )}
                    />
                    {t("Workplace Organisation enrolment")}
                  </div>
                  <div className="">
                    {t("Workplace Organisation enrolment content before email")}
                    <a href="mailto:change4health@dh.gov.hk">
                      change4health@dh.gov.hk 
                    </a>
                    {t("Workplace Organisation enrolment content after email")}
                  </div>
                </div>
              </div>
            </div>

            <div className="row elife-box-content">
              <div className="col-md-12 ">
                <h3>{t("How to enrol in app title")}</h3>
                {t("How to enrol in app content")}
                <div className="game-mode-title">
                  {t("Download Tutorial Content")}
                </div>
                <div className="row step-download-row">
                  <div className="col-sm-12 col-md-4 tutorial-qr-col">
                    <img
                      className="tutorial-qr"
                      src={load(
                        `./resources/images/elife/life-tutorial-download-qr.jpg`
                      )}
                    />
                  </div>
                  <div className="col-sm-12 col-md-8 row life-download-btn">
                    <div className="col-xs-6 col-sm-6">
                      <a
                        id="btnDownloadAppStore"
                        href={appStoreDownloadURL}
                        rel="noopener noreferrer"
                        className="second-download-now-href"
                        target="_blank"
                        tabIndex="0"
                      >
                        <img
                          id="imgBtnDownloadAppStore"
                          src={load(
                            `./resources/images/${locale}/app_store.png`
                          )}
                          alt={t("Download at App Store")}
                        />
                      </a>
                    </div>
                    <div className="col-xs-6 col-sm-6">
                      <a
                        id="btnDownloadGooglePlay"
                        href={googelPlayDownloadURL}
                        rel="noopener noreferrer"
                        className="second-download-now-href"
                        target="_blank"
                        tabIndex="0"
                      >
                        <img
                          id="imgBtnDownloadGooglePlay"
                          src={load(
                            `./resources/images/${locale}/google_play.png`
                          )}
                          alt={t("Download at Google Play")}
                        />
                      </a>
                    </div>
                    <div className="col-xs-6 col-sm-6">
                      <a
                        id="btnDownloadHuawei"
                        href={huaweiDownloadURL}
                        rel="noopener noreferrer"
                        className="second-download-now-href"
                        target="_blank"
                        tabIndex="0"
                      >
                        <img
                          id="imgBtnDownloadHuawei"
                          src={load(`./resources/images/${locale}/huawei.png`)}
                          alt={t("Download at Huawei App Gallery")}
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  ref={this.youtubeContainer}
                  className="second-youtube-container youtube-after-content"
                >
                  <iframe
                    id="videoChildGrowth"
                    key={locale}
                    className="second-responsive-iframe"
                    title={t("Video des")}
                    src="https://www.youtube.com/embed/y_0wLuuMG44"
                    alt={t("Video")}
                    tabIndex="0"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
          <div className="container faq-container">
            <div className="row">
              {this.state.sections.map((section) => {
                return (
                  <div
                    id={`faq-section-${section.slug}`}
                    className="faq-section"
                  >
                    {this.state.questions.map((question) => {
                      return (
                        <div
                          id={`faq-question-container-${question.sectionSlug}-${question.slug}`}
                          className=""
                        >
                          <div
                            className="video-accordion elife-box-content row"
                            onClick={() =>
                              this.toggleQuestion(
                                question.sectionSlug,
                                question.slug
                              )
                            }
                          >
                            <div className="col-md-12">
                              <img
                                className="step-accordion-icon"
                                src={load(question.icon)}
                              />
                              <h3>{t(question.question)}</h3>
                              <img
                                className="faq-expand-icon"
                                src={load(
                                  `${
                                    question.isOpen
                                      ? "./resources/images/arrow-up.png"
                                      : "./resources/images/arrow-down.png"
                                  }`
                                )}
                              />
                            </div>
                            {question.isOpen && (
                              <div className="faq-answer">
                                <div className="accordion-content">
                                  {t(question.content)}
                                </div>

                                <div className="accordion-video-title">
                                  {t(question.videoTitle)}
                                </div>

                                <div
                                  ref={this.youtubeContainer}
                                  className="second-youtube-container youtube-after-content"
                                >
                                  <iframe
                                    key={locale}
                                    className="second-responsive-iframe"
                                    src={t(question.answer)}
                                    alt={t("Video")}
                                    tabIndex="0"
                                  ></iframe>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="empty-space-100"></div>

        <Footer {...this.props} />
      </div>
    );
  }
}

export default PageHOC(LifeOverview);
